import React, {useCallback, useEffect} from "../../_snowpack/pkg/react.js";
import {useAudioPlayer} from "../../_snowpack/link/packages/client-react/dist/index.js";
import {Play, Stop} from "../../_snowpack/pkg/@styled-icons/boxicons-regular.js";
import styled, {css} from "../../_snowpack/pkg/styled-components.js";
import {volumeAtom} from "../state/playerConfig.js";
import {useAtom} from "../../_snowpack/pkg/jotai.js";
const buttonStyle = css`
  cursor: pointeR;

  &:hover {
    color: #ccc;
  }

  &:active {
    color: #aaa;
  }
`;
const StyledPlay = styled(Play)`
  ${buttonStyle}
`;
const StyledStop = styled(Stop)`
  ${buttonStyle}
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const Player = () => {
  const url = window.location.hash.slice(1) || "wss://riddim-out.henrik.ninja/test-tone";
  const {play, stop, playing, setVolume} = useAudioPlayer(url);
  const [volume, setSavedVolume] = useAtom(volumeAtom);
  const handleVolumeChange = useCallback((e) => {
    const val = e.currentTarget.value;
    setSavedVolume(val);
  }, [setVolume, setSavedVolume]);
  useEffect(() => {
    setVolume(Math.pow(volume, 2));
  }, [volume]);
  return /* @__PURE__ */ React.createElement(Wrapper, null, /* @__PURE__ */ React.createElement("input", {
    type: "range",
    step: "any",
    min: "0",
    max: "1",
    onChange: handleVolumeChange,
    value: volume
  }), playing ? /* @__PURE__ */ React.createElement(StyledStop, {
    size: 200,
    onClick: stop
  }) : /* @__PURE__ */ React.createElement(StyledPlay, {
    size: 200,
    onClick: play
  }));
};
export default Player;
