import '../common/index-d9d902f0.js';
import { a as atom } from '../common/index-44d6afd4.js';
import '../common/_commonjsHelpers-8c19dec8.js';
import '../common/process-2545f00a.js';

const createJSONStorage = (getStringStorage) => ({
  getItem: (key) => {
    const value = getStringStorage().getItem(key);
    if (value instanceof Promise) {
      return value.then((v) => JSON.parse(v || ""));
    }
    return JSON.parse(value || "");
  },
  setItem: (key, newValue) => {
    getStringStorage().setItem(key, JSON.stringify(newValue));
  }
});
const defaultStorage = createJSONStorage(() => localStorage);
function atomWithStorage(key, initialValue, storage = defaultStorage) {
  const getInitialValue = () => {
    try {
      const value = storage.getItem(key);
      if (value instanceof Promise) {
        return value.catch(() => initialValue);
      }
      return value;
    } catch {
      return initialValue;
    }
  };
  const baseAtom = atom(storage.delayInit ? initialValue : getInitialValue());
  baseAtom.onMount = (setAtom) => {
    let unsub;
    if (storage.subscribe) {
      unsub = storage.subscribe(key, setAtom);
    }
    if (storage.delayInit) {
      const value = getInitialValue();
      if (value instanceof Promise) {
        value.then(setAtom);
      } else {
        setAtom(value);
      }
    }
    return unsub;
  };
  const anAtom = atom((get) => get(baseAtom), (get, set, update) => {
    const newValue = typeof update === "function" ? update(get(baseAtom)) : update;
    set(baseAtom, newValue);
    storage.setItem(key, newValue);
  });
  return anAtom;
}

export { atomWithStorage };
