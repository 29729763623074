/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "../../../../../pkg/protobufjs/minimal.js";

// Common aliases
const $Reader = $protobuf.Reader, $Writer = $protobuf.Writer, $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

export const StatusUpdate = $root.StatusUpdate = (() => {

    /**
     * Properties of a StatusUpdate.
     * @exports IStatusUpdate
     * @interface IStatusUpdate
     * @property {StatusUpdate.ISettings|null} [settings] StatusUpdate settings
     */

    /**
     * Constructs a new StatusUpdate.
     * @exports StatusUpdate
     * @classdesc Represents a StatusUpdate.
     * @implements IStatusUpdate
     * @constructor
     * @param {IStatusUpdate=} [properties] Properties to set
     */
    function StatusUpdate(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * StatusUpdate settings.
     * @member {StatusUpdate.ISettings|null|undefined} settings
     * @memberof StatusUpdate
     * @instance
     */
    StatusUpdate.prototype.settings = null;

    /**
     * Creates a new StatusUpdate instance using the specified properties.
     * @function create
     * @memberof StatusUpdate
     * @static
     * @param {IStatusUpdate=} [properties] Properties to set
     * @returns {StatusUpdate} StatusUpdate instance
     */
    StatusUpdate.create = function create(properties) {
        return new StatusUpdate(properties);
    };

    /**
     * Encodes the specified StatusUpdate message. Does not implicitly {@link StatusUpdate.verify|verify} messages.
     * @function encode
     * @memberof StatusUpdate
     * @static
     * @param {IStatusUpdate} message StatusUpdate message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    StatusUpdate.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.settings != null && Object.hasOwnProperty.call(message, "settings"))
            $root.StatusUpdate.Settings.encode(message.settings, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a StatusUpdate message from the specified reader or buffer.
     * @function decode
     * @memberof StatusUpdate
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {StatusUpdate} StatusUpdate
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    StatusUpdate.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.StatusUpdate();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.settings = $root.StatusUpdate.Settings.decode(reader, reader.uint32());
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    StatusUpdate.Settings = (function() {

        /**
         * Properties of a Settings.
         * @memberof StatusUpdate
         * @interface ISettings
         * @property {number|null} [sampleRate] Settings sampleRate
         * @property {number|null} [chunkSize] Settings chunkSize
         * @property {number|null} [channels] Settings channels
         * @property {number|null} [sampleByteSize] Settings sampleByteSize
         * @property {number|null} [maxQueueSize] Settings maxQueueSize
         */

        /**
         * Constructs a new Settings.
         * @memberof StatusUpdate
         * @classdesc Represents a Settings.
         * @implements ISettings
         * @constructor
         * @param {StatusUpdate.ISettings=} [properties] Properties to set
         */
        function Settings(properties) {
            if (properties)
                for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                    if (properties[keys[i]] != null)
                        this[keys[i]] = properties[keys[i]];
        }

        /**
         * Settings sampleRate.
         * @member {number} sampleRate
         * @memberof StatusUpdate.Settings
         * @instance
         */
        Settings.prototype.sampleRate = 0;

        /**
         * Settings chunkSize.
         * @member {number} chunkSize
         * @memberof StatusUpdate.Settings
         * @instance
         */
        Settings.prototype.chunkSize = 0;

        /**
         * Settings channels.
         * @member {number} channels
         * @memberof StatusUpdate.Settings
         * @instance
         */
        Settings.prototype.channels = 0;

        /**
         * Settings sampleByteSize.
         * @member {number} sampleByteSize
         * @memberof StatusUpdate.Settings
         * @instance
         */
        Settings.prototype.sampleByteSize = 0;

        /**
         * Settings maxQueueSize.
         * @member {number} maxQueueSize
         * @memberof StatusUpdate.Settings
         * @instance
         */
        Settings.prototype.maxQueueSize = 0;

        /**
         * Creates a new Settings instance using the specified properties.
         * @function create
         * @memberof StatusUpdate.Settings
         * @static
         * @param {StatusUpdate.ISettings=} [properties] Properties to set
         * @returns {StatusUpdate.Settings} Settings instance
         */
        Settings.create = function create(properties) {
            return new Settings(properties);
        };

        /**
         * Encodes the specified Settings message. Does not implicitly {@link StatusUpdate.Settings.verify|verify} messages.
         * @function encode
         * @memberof StatusUpdate.Settings
         * @static
         * @param {StatusUpdate.ISettings} message Settings message or plain object to encode
         * @param {$protobuf.Writer} [writer] Writer to encode to
         * @returns {$protobuf.Writer} Writer
         */
        Settings.encode = function encode(message, writer) {
            if (!writer)
                writer = $Writer.create();
            if (message.sampleRate != null && Object.hasOwnProperty.call(message, "sampleRate"))
                writer.uint32(/* id 1, wireType 0 =*/8).int32(message.sampleRate);
            if (message.chunkSize != null && Object.hasOwnProperty.call(message, "chunkSize"))
                writer.uint32(/* id 2, wireType 0 =*/16).int32(message.chunkSize);
            if (message.channels != null && Object.hasOwnProperty.call(message, "channels"))
                writer.uint32(/* id 3, wireType 0 =*/24).int32(message.channels);
            if (message.sampleByteSize != null && Object.hasOwnProperty.call(message, "sampleByteSize"))
                writer.uint32(/* id 4, wireType 0 =*/32).int32(message.sampleByteSize);
            if (message.maxQueueSize != null && Object.hasOwnProperty.call(message, "maxQueueSize"))
                writer.uint32(/* id 5, wireType 0 =*/40).int32(message.maxQueueSize);
            return writer;
        };

        /**
         * Decodes a Settings message from the specified reader or buffer.
         * @function decode
         * @memberof StatusUpdate.Settings
         * @static
         * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
         * @param {number} [length] Message length if known beforehand
         * @returns {StatusUpdate.Settings} Settings
         * @throws {Error} If the payload is not a reader or valid buffer
         * @throws {$protobuf.util.ProtocolError} If required fields are missing
         */
        Settings.decode = function decode(reader, length) {
            if (!(reader instanceof $Reader))
                reader = $Reader.create(reader);
            let end = length === undefined ? reader.len : reader.pos + length, message = new $root.StatusUpdate.Settings();
            while (reader.pos < end) {
                let tag = reader.uint32();
                switch (tag >>> 3) {
                case 1:
                    message.sampleRate = reader.int32();
                    break;
                case 2:
                    message.chunkSize = reader.int32();
                    break;
                case 3:
                    message.channels = reader.int32();
                    break;
                case 4:
                    message.sampleByteSize = reader.int32();
                    break;
                case 5:
                    message.maxQueueSize = reader.int32();
                    break;
                default:
                    reader.skipType(tag & 7);
                    break;
                }
            }
            return message;
        };

        return Settings;
    })();

    return StatusUpdate;
})();

export const AudioChunk = $root.AudioChunk = (() => {

    /**
     * Properties of an AudioChunk.
     * @exports IAudioChunk
     * @interface IAudioChunk
     * @property {Uint8Array|null} [chunk] AudioChunk chunk
     */

    /**
     * Constructs a new AudioChunk.
     * @exports AudioChunk
     * @classdesc Represents an AudioChunk.
     * @implements IAudioChunk
     * @constructor
     * @param {IAudioChunk=} [properties] Properties to set
     */
    function AudioChunk(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * AudioChunk chunk.
     * @member {Uint8Array} chunk
     * @memberof AudioChunk
     * @instance
     */
    AudioChunk.prototype.chunk = $util.newBuffer([]);

    /**
     * Creates a new AudioChunk instance using the specified properties.
     * @function create
     * @memberof AudioChunk
     * @static
     * @param {IAudioChunk=} [properties] Properties to set
     * @returns {AudioChunk} AudioChunk instance
     */
    AudioChunk.create = function create(properties) {
        return new AudioChunk(properties);
    };

    /**
     * Encodes the specified AudioChunk message. Does not implicitly {@link AudioChunk.verify|verify} messages.
     * @function encode
     * @memberof AudioChunk
     * @static
     * @param {IAudioChunk} message AudioChunk message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    AudioChunk.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.chunk != null && Object.hasOwnProperty.call(message, "chunk"))
            writer.uint32(/* id 1, wireType 2 =*/10).bytes(message.chunk);
        return writer;
    };

    /**
     * Decodes an AudioChunk message from the specified reader or buffer.
     * @function decode
     * @memberof AudioChunk
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {AudioChunk} AudioChunk
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    AudioChunk.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.AudioChunk();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.chunk = reader.bytes();
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return AudioChunk;
})();

export const ServerMessage = $root.ServerMessage = (() => {

    /**
     * Properties of a ServerMessage.
     * @exports IServerMessage
     * @interface IServerMessage
     * @property {IStatusUpdate|null} [statusUpdate] ServerMessage statusUpdate
     * @property {IAudioChunk|null} [audioChunk] ServerMessage audioChunk
     */

    /**
     * Constructs a new ServerMessage.
     * @exports ServerMessage
     * @classdesc Represents a ServerMessage.
     * @implements IServerMessage
     * @constructor
     * @param {IServerMessage=} [properties] Properties to set
     */
    function ServerMessage(properties) {
        if (properties)
            for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
                if (properties[keys[i]] != null)
                    this[keys[i]] = properties[keys[i]];
    }

    /**
     * ServerMessage statusUpdate.
     * @member {IStatusUpdate|null|undefined} statusUpdate
     * @memberof ServerMessage
     * @instance
     */
    ServerMessage.prototype.statusUpdate = null;

    /**
     * ServerMessage audioChunk.
     * @member {IAudioChunk|null|undefined} audioChunk
     * @memberof ServerMessage
     * @instance
     */
    ServerMessage.prototype.audioChunk = null;

    // OneOf field names bound to virtual getters and setters
    let $oneOfFields;

    /**
     * ServerMessage message.
     * @member {"statusUpdate"|"audioChunk"|undefined} message
     * @memberof ServerMessage
     * @instance
     */
    Object.defineProperty(ServerMessage.prototype, "message", {
        get: $util.oneOfGetter($oneOfFields = ["statusUpdate", "audioChunk"]),
        set: $util.oneOfSetter($oneOfFields)
    });

    /**
     * Creates a new ServerMessage instance using the specified properties.
     * @function create
     * @memberof ServerMessage
     * @static
     * @param {IServerMessage=} [properties] Properties to set
     * @returns {ServerMessage} ServerMessage instance
     */
    ServerMessage.create = function create(properties) {
        return new ServerMessage(properties);
    };

    /**
     * Encodes the specified ServerMessage message. Does not implicitly {@link ServerMessage.verify|verify} messages.
     * @function encode
     * @memberof ServerMessage
     * @static
     * @param {IServerMessage} message ServerMessage message or plain object to encode
     * @param {$protobuf.Writer} [writer] Writer to encode to
     * @returns {$protobuf.Writer} Writer
     */
    ServerMessage.encode = function encode(message, writer) {
        if (!writer)
            writer = $Writer.create();
        if (message.statusUpdate != null && Object.hasOwnProperty.call(message, "statusUpdate"))
            $root.StatusUpdate.encode(message.statusUpdate, writer.uint32(/* id 1, wireType 2 =*/10).fork()).ldelim();
        if (message.audioChunk != null && Object.hasOwnProperty.call(message, "audioChunk"))
            $root.AudioChunk.encode(message.audioChunk, writer.uint32(/* id 2, wireType 2 =*/18).fork()).ldelim();
        return writer;
    };

    /**
     * Decodes a ServerMessage message from the specified reader or buffer.
     * @function decode
     * @memberof ServerMessage
     * @static
     * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
     * @param {number} [length] Message length if known beforehand
     * @returns {ServerMessage} ServerMessage
     * @throws {Error} If the payload is not a reader or valid buffer
     * @throws {$protobuf.util.ProtocolError} If required fields are missing
     */
    ServerMessage.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader))
            reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length, message = new $root.ServerMessage();
        while (reader.pos < end) {
            let tag = reader.uint32();
            switch (tag >>> 3) {
            case 1:
                message.statusUpdate = $root.StatusUpdate.decode(reader, reader.uint32());
                break;
            case 2:
                message.audioChunk = $root.AudioChunk.decode(reader, reader.uint32());
                break;
            default:
                reader.skipType(tag & 7);
                break;
            }
        }
        return message;
    };

    return ServerMessage;
})();

export { $root as default };
