import { p as process } from './process-2545f00a.js';
import { r as react } from './index-d9d902f0.js';

var __defProp = Object.defineProperty;
var __defProps = Object.defineProperties;
var __getOwnPropDescs = Object.getOwnPropertyDescriptors;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {enumerable: true, configurable: true, writable: true, value}) : obj[key] = value;
var __spreadValues = (a, b) => {
  for (var prop in b || (b = {}))
    if (__hasOwnProp.call(b, prop))
      __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols)
    for (var prop of __getOwnPropSymbols(b)) {
      if (__propIsEnum.call(b, prop))
        __defNormalProp(a, prop, b[prop]);
    }
  return a;
};
var __spreadProps = (a, b) => __defProps(a, __getOwnPropDescs(b));
const hasInitialValue = (atom2) => "init" in atom2;
const IS_EQUAL_PROMISE = Symbol();
const INTERRUPT_PROMISE = Symbol();
const isInterruptablePromise = (promise) => !!promise[INTERRUPT_PROMISE];
const createInterruptablePromise = (promise) => {
  let interrupt;
  const interruptablePromise = new Promise((resolve, reject) => {
    interrupt = resolve;
    promise.then(resolve, reject);
  });
  interruptablePromise[IS_EQUAL_PROMISE] = (p) => p === interruptablePromise || p === promise;
  interruptablePromise[INTERRUPT_PROMISE] = interrupt;
  return interruptablePromise;
};
const READ_ATOM = "r";
const WRITE_ATOM = "w";
const FLUSH_PENDING = "f";
const SUBSCRIBE_ATOM = "s";
const RESTORE_ATOMS = "h";
const DEV_GET_ATOM_STATE = "a";
const DEV_GET_MOUNTED = "m";
const createStore = (initialValues, stateListener) => {
  const atomStateMap = new WeakMap();
  const mountedMap = new WeakMap();
  const pendingMap = new Map();
  if (initialValues) {
    for (const [atom2, value] of initialValues) {
      const atomState = {v: value, r: 0, d: new Map()};
      if (typeof process === "object" && "production" !== "production") {
        Object.freeze(atomState);
        if (!hasInitialValue(atom2)) {
          console.warn("Found initial value for derived atom which can cause unexpected behavior", atom2);
        }
      }
      atomStateMap.set(atom2, atomState);
    }
  }
  const getAtomState = (atom2) => atomStateMap.get(atom2);
  const wipAtomState = (atom2, dependencies) => {
    const atomState = getAtomState(atom2);
    const nextAtomState = __spreadProps(__spreadValues({
      r: 0
    }, atomState), {
      d: dependencies ? new Map(Array.from(dependencies).map((a) => {
        var _a, _b;
        return [a, (_b = (_a = getAtomState(a)) == null ? void 0 : _a.r) != null ? _b : 0];
      })) : (atomState == null ? void 0 : atomState.d) || new Map()
    });
    return [nextAtomState, (atomState == null ? void 0 : atomState.d) || new Map()];
  };
  const setAtomValue = (atom2, value, dependencies, promise) => {
    var _a, _b;
    const [atomState, prevDependencies] = wipAtomState(atom2, dependencies);
    if (promise && !((_a = atomState.p) == null ? void 0 : _a[IS_EQUAL_PROMISE](promise))) {
      return;
    }
    (_b = atomState.c) == null ? void 0 : _b.call(atomState);
    delete atomState.e;
    delete atomState.p;
    delete atomState.c;
    delete atomState.i;
    if (!("v" in atomState) || !Object.is(atomState.v, value)) {
      atomState.v = value;
      ++atomState.r;
      if (atomState.d.has(atom2)) {
        atomState.d.set(atom2, atomState.r);
      }
    }
    commitAtomState(atom2, atomState, dependencies && prevDependencies);
  };
  const setAtomReadError = (atom2, error, dependencies, promise) => {
    var _a, _b;
    const [atomState, prevDependencies] = wipAtomState(atom2, dependencies);
    if (promise && !((_a = atomState.p) == null ? void 0 : _a[IS_EQUAL_PROMISE](promise))) {
      return;
    }
    (_b = atomState.c) == null ? void 0 : _b.call(atomState);
    delete atomState.p;
    delete atomState.c;
    delete atomState.i;
    atomState.e = error;
    commitAtomState(atom2, atomState, prevDependencies);
  };
  const setAtomReadPromise = (atom2, promise, dependencies) => {
    var _a, _b;
    const [atomState, prevDependencies] = wipAtomState(atom2, dependencies);
    if ((_a = atomState.p) == null ? void 0 : _a[IS_EQUAL_PROMISE](promise)) {
      return;
    }
    (_b = atomState.c) == null ? void 0 : _b.call(atomState);
    if (isInterruptablePromise(promise)) {
      atomState.p = promise;
      delete atomState.c;
    } else {
      const interruptablePromise = createInterruptablePromise(promise);
      atomState.p = interruptablePromise;
      atomState.c = interruptablePromise[INTERRUPT_PROMISE];
    }
    commitAtomState(atom2, atomState, prevDependencies);
  };
  const setAtomInvalidated = (atom2) => {
    const [atomState] = wipAtomState(atom2);
    atomState.i = atomState.r;
    commitAtomState(atom2, atomState);
  };
  const setAtomWritePromise = (atom2, promise) => {
    const [atomState] = wipAtomState(atom2);
    if (promise) {
      atomState.w = promise;
    } else {
      delete atomState.w;
    }
    commitAtomState(atom2, atomState);
  };
  const scheduleReadAtomState = (atom2, promise) => {
    promise.finally(() => {
      readAtomState(atom2, true);
    });
  };
  const readAtomState = (atom2, force) => {
    if (!force) {
      const atomState = getAtomState(atom2);
      if (atomState) {
        atomState.d.forEach((_, a) => {
          if (a !== atom2) {
            const aState = getAtomState(a);
            if (aState && !aState.e && !aState.p && aState.r === aState.i) {
              readAtomState(a, true);
            }
          }
        });
        if (Array.from(atomState.d.entries()).every(([a, r]) => {
          const aState = getAtomState(a);
          return aState && !aState.e && !aState.p && aState.r !== aState.i && aState.r === r;
        })) {
          return atomState;
        }
      }
    }
    let error;
    let promise;
    let value;
    const dependencies = new Set();
    try {
      const promiseOrValue = atom2.read((a) => {
        dependencies.add(a);
        const aState = a === atom2 ? getAtomState(a) : readAtomState(a);
        if (aState) {
          if (aState.e) {
            throw aState.e;
          }
          if (aState.p) {
            throw aState.p;
          }
          return aState.v;
        }
        if (hasInitialValue(a)) {
          return a.init;
        }
        throw new Error("no atom init");
      });
      if (promiseOrValue instanceof Promise) {
        promise = promiseOrValue.then((value2) => {
          setAtomValue(atom2, value2, dependencies, promise);
          flushPending();
        }).catch((e) => {
          if (e instanceof Promise) {
            scheduleReadAtomState(atom2, e);
            return e;
          }
          setAtomReadError(atom2, e instanceof Error ? e : new Error(e), dependencies, promise);
          flushPending();
        });
      } else {
        value = promiseOrValue;
      }
    } catch (errorOrPromise) {
      if (errorOrPromise instanceof Promise) {
        promise = errorOrPromise;
      } else if (errorOrPromise instanceof Error) {
        error = errorOrPromise;
      } else {
        error = new Error(errorOrPromise);
      }
    }
    if (error) {
      setAtomReadError(atom2, error, dependencies);
    } else if (promise) {
      setAtomReadPromise(atom2, promise, dependencies);
    } else {
      setAtomValue(atom2, value, dependencies);
    }
    return getAtomState(atom2);
  };
  const readAtom = (readingAtom) => {
    const atomState = readAtomState(readingAtom);
    return atomState;
  };
  const addAtom = (addingAtom) => {
    let mounted = mountedMap.get(addingAtom);
    if (!mounted) {
      mounted = mountAtom(addingAtom);
    }
    flushPending();
    return mounted;
  };
  const canUnmountAtom = (atom2, mounted) => !mounted.l.size && (!mounted.d.size || mounted.d.size === 1 && mounted.d.has(atom2));
  const delAtom = (deletingAtom) => {
    const mounted = mountedMap.get(deletingAtom);
    if (mounted && canUnmountAtom(deletingAtom, mounted)) {
      unmountAtom(deletingAtom);
    }
    flushPending();
  };
  const invalidateDependents = (atom2) => {
    const mounted = mountedMap.get(atom2);
    mounted == null ? void 0 : mounted.d.forEach((dependent) => {
      if (dependent === atom2) {
        return;
      }
      setAtomInvalidated(dependent);
      invalidateDependents(dependent);
    });
  };
  const writeAtomState = (atom2, update) => {
    var _a;
    const writePromise = (_a = getAtomState(atom2)) == null ? void 0 : _a.w;
    if (writePromise) {
      writePromise.then(() => {
        writeAtomState(atom2, update);
        flushPending();
      });
      return;
    }
    const writeGetter = (a, unstable_promise = false) => {
      const aState = readAtomState(a);
      if (aState.e) {
        throw aState.e;
      }
      if (aState.p) {
        if (typeof process === "object" && "production" !== "production") {
          if (unstable_promise) {
            console.info("promise option in getter is an experimental feature.", a);
          } else {
            console.warn("Reading pending atom state in write operation. We throw a promise for now.", a);
          }
        }
        if (unstable_promise) {
          return aState.p.then(() => writeGetter(a, unstable_promise));
        }
        throw aState.p;
      }
      if ("v" in aState) {
        return aState.v;
      }
      if (typeof process === "object" && "production" !== "production") {
        console.warn("[Bug] no value found while reading atom in write operation. This is probably a bug.", a);
      }
      throw new Error("no value found");
    };
    const setter = (a, v) => {
      let promiseOrVoid2;
      if (a === atom2) {
        if (!hasInitialValue(a)) {
          throw new Error("no atom init");
        }
        if (v instanceof Promise) {
          promiseOrVoid2 = v.then((resolvedValue) => {
            setAtomValue(a, resolvedValue);
            invalidateDependents(a);
            flushPending();
          }).catch((e) => {
            setAtomReadError(atom2, e instanceof Error ? e : new Error(e));
            flushPending();
          });
          setAtomReadPromise(atom2, promiseOrVoid2);
        } else {
          setAtomValue(a, v);
        }
        invalidateDependents(a);
      } else {
        promiseOrVoid2 = writeAtomState(a, v);
      }
      flushPending();
      return promiseOrVoid2;
    };
    const promiseOrVoid = atom2.write(writeGetter, setter, update);
    if (promiseOrVoid instanceof Promise) {
      const promise = promiseOrVoid.finally(() => {
        setAtomWritePromise(atom2);
        flushPending();
      });
      setAtomWritePromise(atom2, promise);
    }
    return promiseOrVoid;
  };
  const writeAtom = (writingAtom, update) => {
    const promiseOrVoid = writeAtomState(writingAtom, update);
    flushPending();
    return promiseOrVoid;
  };
  const isActuallyWritableAtom = (atom2) => !!atom2.write;
  const mountAtom = (atom2, initialDependent) => {
    const atomState = readAtomState(atom2);
    atomState.d.forEach((_, a) => {
      if (a !== atom2) {
        const aMounted = mountedMap.get(a);
        if (aMounted) {
          aMounted.d.add(atom2);
        } else {
          mountAtom(a, atom2);
        }
      }
    });
    const mounted = {
      d: new Set(initialDependent && [initialDependent]),
      l: new Set(),
      u: void 0
    };
    mountedMap.set(atom2, mounted);
    if (isActuallyWritableAtom(atom2) && atom2.onMount) {
      const setAtom = (update) => writeAtom(atom2, update);
      mounted.u = atom2.onMount(setAtom);
    }
    return mounted;
  };
  const unmountAtom = (atom2) => {
    var _a;
    const onUnmount = (_a = mountedMap.get(atom2)) == null ? void 0 : _a.u;
    if (onUnmount) {
      onUnmount();
    }
    mountedMap.delete(atom2);
    const atomState = getAtomState(atom2);
    if (atomState) {
      atomState.d.forEach((_, a) => {
        if (a !== atom2) {
          const mounted = mountedMap.get(a);
          if (mounted) {
            mounted.d.delete(atom2);
            if (canUnmountAtom(a, mounted)) {
              unmountAtom(a);
            }
          }
        }
      });
    } else if (typeof process === "object" && "production" !== "production") {
      console.warn("[Bug] could not find atom state to unmount", atom2);
    }
  };
  const mountDependencies = (atom2, atomState, prevDependencies) => {
    const dependencies = new Set(atomState.d.keys());
    prevDependencies.forEach((_, a) => {
      if (dependencies.has(a)) {
        dependencies.delete(a);
        return;
      }
      const mounted = mountedMap.get(a);
      if (mounted) {
        mounted.d.delete(atom2);
        if (canUnmountAtom(a, mounted)) {
          unmountAtom(a);
        }
      }
    });
    dependencies.forEach((a) => {
      const mounted = mountedMap.get(a);
      if (mounted) {
        const dependents = mounted.d;
        dependents.add(atom2);
      } else {
        mountAtom(a, atom2);
      }
    });
  };
  const commitAtomState = (atom2, atomState, prevDependencies) => {
    if (typeof process === "object" && "production" !== "production") {
      Object.freeze(atomState);
    }
    const isNewAtom = !atomStateMap.has(atom2);
    atomStateMap.set(atom2, atomState);
    if (stateListener) {
      stateListener(atom2, isNewAtom);
    }
    if (!pendingMap.has(atom2)) {
      pendingMap.set(atom2, prevDependencies);
    }
  };
  const flushPending = () => {
    const pending = Array.from(pendingMap);
    pendingMap.clear();
    pending.forEach(([atom2, prevDependencies]) => {
      const atomState = getAtomState(atom2);
      if (atomState) {
        if (prevDependencies) {
          mountDependencies(atom2, atomState, prevDependencies);
        }
      } else if (typeof process === "object" && "production" !== "production") {
        console.warn("[Bug] atom state not found in flush", atom2);
      }
      const mounted = mountedMap.get(atom2);
      mounted == null ? void 0 : mounted.l.forEach((listener) => listener());
    });
  };
  const subscribeAtom = (atom2, callback) => {
    const mounted = addAtom(atom2);
    const listeners = mounted.l;
    listeners.add(callback);
    return () => {
      listeners.delete(callback);
      delAtom(atom2);
    };
  };
  const restoreAtoms = (values) => {
    for (const [atom2, value] of values) {
      if (hasInitialValue(atom2)) {
        setAtomValue(atom2, value);
        invalidateDependents(atom2);
      }
    }
    flushPending();
  };
  if (typeof process === "object" && "production" !== "production") {
    return {
      [READ_ATOM]: readAtom,
      [WRITE_ATOM]: writeAtom,
      [FLUSH_PENDING]: flushPending,
      [SUBSCRIBE_ATOM]: subscribeAtom,
      [RESTORE_ATOMS]: restoreAtoms,
      [DEV_GET_ATOM_STATE]: (a) => atomStateMap.get(a),
      [DEV_GET_MOUNTED]: (a) => mountedMap.get(a)
    };
  }
  return {
    [READ_ATOM]: readAtom,
    [WRITE_ATOM]: writeAtom,
    [FLUSH_PENDING]: flushPending,
    [SUBSCRIBE_ATOM]: subscribeAtom,
    [RESTORE_ATOMS]: restoreAtoms
  };
};
const createScopeContainerForProduction = (initialValues) => {
  const store = createStore(initialValues);
  return [store];
};
const createScopeContainerForDevelopment = (initialValues) => {
  const devStore = {
    listeners: new Set(),
    subscribe: (callback) => {
      devStore.listeners.add(callback);
      return () => {
        devStore.listeners.delete(callback);
      };
    },
    atoms: Array.from(initialValues != null ? initialValues : []).map(([a]) => a)
  };
  const stateListener = (updatedAtom, isNewAtom) => {
    if (isNewAtom) {
      devStore.atoms = [...devStore.atoms, updatedAtom];
    }
    Promise.resolve().then(() => {
      devStore.listeners.forEach((listener) => listener());
    });
  };
  const store = createStore(initialValues, stateListener);
  return [store, devStore];
};
const createScopeContainer = typeof process === "object" && "production" !== "production" ? createScopeContainerForDevelopment : createScopeContainerForProduction;
const ScopeContextMap = new Map();
const getScopeContext = (scope) => {
  if (!ScopeContextMap.has(scope)) {
    ScopeContextMap.set(scope, react.createContext(createScopeContainer()));
  }
  return ScopeContextMap.get(scope);
};
let keyCount = 0;
function atom(read, write) {
  const key = `atom${++keyCount}`;
  const config = {
    toString: () => key
  };
  if (typeof read === "function") {
    config.read = read;
  } else {
    config.init = read;
    config.read = (get) => get(config);
    config.write = (get, set, update) => {
      set(config, typeof update === "function" ? update(get(config)) : update);
    };
  }
  if (write) {
    config.write = write;
  }
  return config;
}
const isWritable = (atom2) => !!atom2.write;
function useAtom(atom2, scope) {
  if ("scope" in atom2) {
    console.warn("atom.scope is deprecated. Please do useAtom(atom, scope) instead.");
    scope = atom2.scope;
  }
  const ScopeContext = getScopeContext(scope);
  const [store] = react.useContext(ScopeContext);
  const getAtomValue = react.useCallback(() => {
    const atomState = store[READ_ATOM](atom2);
    if (atomState.e) {
      throw atomState.e;
    }
    if (atomState.p) {
      throw atomState.p;
    }
    if (atomState.w) {
      throw atomState.w;
    }
    if ("v" in atomState) {
      return atomState.v;
    }
    throw new Error("no atom value");
  }, [store, atom2]);
  const [value, forceUpdate] = react.useReducer(getAtomValue, void 0, getAtomValue);
  react.useEffect(() => {
    const unsubscribe = store[SUBSCRIBE_ATOM](atom2, forceUpdate);
    forceUpdate();
    return unsubscribe;
  }, [store, atom2]);
  react.useEffect(() => {
    store[FLUSH_PENDING]();
  });
  const setAtom = react.useCallback((update) => {
    if (isWritable(atom2)) {
      return store[WRITE_ATOM](atom2, update);
    } else {
      throw new Error("not writable atom");
    }
  }, [store, atom2]);
  react.useDebugValue(value);
  return [value, setAtom];
}

export { atom as a, useAtom as u };
