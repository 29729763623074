import {createGlobalStyle} from "../../_snowpack/pkg/styled-components.js";
export const GlobalStyles = createGlobalStyle`
  body, html, #app {
    height: 100%;
  }

  body {
    background: #131321;
    color: white;
    font-family: sans-serif;
    margin: 0;
    padding: 0;
  }
`;
